<div id="root">
  <div class="App-Container is-darkBackground App-Container--noAnimation flex-container justify-content-center" data-testid="checkout-container">
    <div class="App App--multiItem">
      <div class="App-Overview is-darkBackground" style="background-color: rgb(18, 18, 18);">
        <header class="Header is-darkBackground" style="background-color: rgb(18, 18, 18);">
          <div class="Header-content flex-container justify-content-space-between align-items-stretch">
            <div class="Header-business flex-item width-grow flex-container align-items-center">
              <div class="Header-merchantLogoWithLabel">
                <div class="HeaderImage HeaderImage--logo flex-item width-fixed flex-container justify-content-center align-items-center width-fixed">
                  <img alt="{{siteMeta.name}}. logo" [src]="siteMeta.lightBigLogoUrl" class="HeaderImage-img" loading="lazy"></div>
                <span class="Header-businessLink-label Text Text-color--gray800 Text-fontSize--14 Text-fontWeight--500">Back</span>
              </div>
            </div>
            <div class="Header-actions flex-item width-fixed">
              <button class="Button Header-viewDetailsButton Button--link Button--sm" type="button">
                <div class="flex-container justify-content-flex-end align-items-center">
                  <svg class="InlineSVG Icon Button-Icon Button-Icon--right Icon--sm Icon--square" focusable="false" viewBox="0 0 12 12">
                    <path d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z" fill-rule="evenodd"></path>
                  </svg>
                  <div class="Header-detailsLabel" style="position: unset; opacity: 1; transform: none;"><span class="Text Text-color--default Text-fontSize--13 Text-fontWeight--400 Text--truncate">Details</span></div>
                </div>
              </button>
            </div>
          </div>
        </header>
        <div class="OrderSummaryColumn" data-testid="order-summary-column">
          <div data-testid="product-summary" class="ProductSummary is-clickable">
            <div class="ProductSummary-productImageContainer is-clickable" data-testid="product-summary-product-image" *ngIf="pack.Image">
              <div class="ProductImage-container" data-testid="product-image">
                <img [src]="pack.Image" [alt]="menuPack.Name" class="ProductImage-image" fetchpriority="high">
              </div>
            </div>
            <div class="ProductSummary-info is-clickable is-darkBackground">
              <span class="ProductSummary-name Text Text-color--gray500 Text-fontSize--16 Text-fontWeight--500" data-testid="product-summary-name">Pay {{siteMeta.name}}.</span>
              <div class="ProductSummary-amountsContainer false">
                <div class="ProductSummary-totalsRead" style="opacity: 1;">
                  <div class="ProductSummary-totalAmountContainer is-clickable" style="opacity: 1;">
                    <span class="ProductSummary-totalAmount Text Text-color--default Text-fontWeight--600 Text--tabularNumbers" id="ProductSummary-totalAmount" data-testid="product-summary-total-amount">
                      <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span>
                    </span>
                  </div>
                  <div class="AnimateSinglePresence">
                    <div class="AnimateSinglePresenceItem">
                      <div>
                        <div class="ProductSummary-amountsDescriptions" data-testid="product-summary-description"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ProductSummary-buttonsContainer" data-testid="product-summary-buttons"></div>
            </div>
          </div>
          <section data-testid="order-details" class="OrderDetails App-Overview-OrderDetails my6">
            <ul class="OrderDetails-items">
              <li class="OrderDetails-item" style="opacity: 1; transform: scale(1); height: auto; margin-bottom: 16px;">
                <div class="LineItem flex-container align-items-flex-start">
                  <div class="LineItem-imageContainer mr4 flex-item width-fixed" data-testid="line-item-image" *ngIf="pack.Image">
                    <img [src]="pack.Image" [alt]="menuPack.Name" class="LineItem-image"></div>
                  <div class="flex-container justify-content-space-between align-items-baseline wrap-wrap width-grow">
                    <div class="flex-column-break" style="order: 2;"></div>
                    <div class="flex-column-break" style="order: 5;"></div>
                    <div class="LineItem-productName flex-item width-auto" data-testid="line-item-product-name" style="order: 0;">
                      <span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">
                        <div class="ExpandableText false">
                          <div data-testid="" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">{{menuPack.Name}}</div>
                        </div>
                      </span>
                    </div>
                    <div class="flex-container direction-column wrap-wrap" style="width: 100%; order: 3;">
                      <div class="LineItem-productDescription flex-item width-12" style="order: -1;">
                        <div class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                          <div class="ExpandableText false">
                            <div data-testid="line-item-product-description" class="ExpandableText--truncated" style="-webkit-line-clamp: 2;">
                              {{pack.Description}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex-container direction-row wrap-wrap">
                        <div class="LineItem-description flex-item width-grow" style="order: 3;"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400"></span></div>
                        <div class="LineItem-amountDetail flex-item width-auto flex-container align-items-flex-end width-auto" data-testid="line-item-amount-detail" style="order: 4;">
                          <div class="flex-item width-auto flex-item-align-right">
                            <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                              <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span> {{getTermDuration()}}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="LineItem-tierBreakdown flex-item width-auto flex-item-align-left" style="order: 6;">
                      <div class="flex-container" data-testid="line-item-tiers"></div>
                    </div>
                    <div class="flex-container Placeholder" style="opacity: 1;">
                      <div class="ml2 flex-item width-fixed" data-testid="line-item-total-amount" style="order: 1;"><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500 Text--tabularNumbers">
                        <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span></span></div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="OrderDetails-footer">
              <div class="is-indented">
                <div class="OrderDetailsFooter-subtotal flex-container justify-content-space-between">
                  <span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">Subtotal</span>
                  <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--600 Text--tabularNumbers" id="OrderDetailsFooter-SubtotalAmount">
                    <span class="CurrencyAmount">US{{formatCurrency((menuPack.Price / 100))}}</span></span></div>
                </div>
                <div class="OrderDetailsFooter-subtotalItems flex-container direction-column">
                  <div class="FadeWrapper"></div>
                  <div>
                    <div class="OrderDetailsSubtotalItem flex-container justify-content-space-between">
                      <div>
                        <div class="flex-container">
                          <span class="OrderDetails-subtotalItemLabel-Text Text Text-color--gray900 Text-fontSize--14">
                            Tax
                          </span>
                        </div>
                      </div>
                      <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--gray400 Text-fontSize--14 Text--tabularNumbers">
                        <span class="CurrencyAmount">US{{formatCurrency((taxAmount / 100))}}</span></span></div>
                    </div>
                  </div>
                </div>
                <div class="OrderDetails-total flex-container justify-content-space-between">
                  <span><span class="Text Text-color--default Text-fontSize--14 Text-fontWeight--500">Total due</span></span>
                  <div class="flex-container Placeholder" style="opacity: 1;"><span class="Text Text-color--default Text-fontSize--16 Text-fontWeight--600 Text--tabularNumbers" id="OrderDetails-TotalAmount">
                    <span class="CurrencyAmount">US{{formatCurrency(((menuPack.Price / 100) + (taxAmount / 100)))}}</span></span></div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="App-Payment">
        <main>
          <div class="CheckoutPaymentForm">
            <div style="height: 36.7969px;">
              <div>
                <div class="PaymentRequestOrHeader" style="display: inherit; height: auto;">
                  <div class="AnimateSinglePresence">
                    <div class="AnimateSinglePresenceItem">
                      <div>
                        <div class="PaymentHeaderContainer" style="display: block; opacity: 1;">
                          <h2 class="PaymentHeader Text Text-color--gray600 Text-fontSize--16 Text-fontWeight--500">Contact information</h2>
                        </div>
                        <div class="ButtonAndDividerContainer" style="display: none; opacity: 0; height: 0px;">
                          <div class="ButtonContainer">
                            <div class="ButtonWrapper">
                              <div class="ButtonWrapper"></div>
                            </div>
                          </div>
                          <div></div>
                          <div class="Divider">
                            <hr>
                            <p class="Divider-Text Text Text-color--gray400 Text-fontSize--14 Text-fontWeight--400"><span class="DividerText">Or pay another way</span></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form class="PaymentForm-form" novalidate="" *ngIf="paymentForm" [formGroup]="paymentForm" (ngSubmit)="onSubmit()">
              <input type="hidden" formControlName="MenuPackId" />
              <input type="hidden" formControlName="Amount" />
              <input type="hidden" formControlName="Currency" />
              <div class="" style="position: relative;">
                <div class="App-Global-Fields flex-container spacing-16 direction-column wrap-wrap">
                  <div class="flex-item width-12">
                    <div class="FormFieldGroup" data-qa="FormFieldGroup-email">
                      <div class="FormFieldGroup-labelContainer flex-container justify-content-space-between">
                        <label for="email-fieldset">
                          <span class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Contact information</span></label>
                        <div style="opacity: 1; transform: none;"></div>
                      </div>
                      <fieldset class="FormFieldGroup-Fieldset" id="email-fieldset">
                        <div class="FormFieldGroup-container">
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childTop">
                            <div class="FormFieldInput">
                              <div class="CheckoutInputContainer">
                                <div class="CheckoutInputContainer-placeholderIcon CheckoutInputContainer--hasMailIcon">
                                  <svg class="InlineSVG Icon Icon--md" focusable="false" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M16 3.6C16 2.72 15.28 2 14.4 2H1.6C0.72 2 0 2.72 0 3.6L0 12.4C0 13.28 0.72 14 1.6 14H14.4C15.28 14 16 13.28 16 12.4V3.6ZM14.4 3.6L8 7.6L1.6 3.6H14.4ZM14.4 12.4H1.6V5.2L8 9.2L14.4 5.2V12.4Z"></path>
                                  </svg>
                                </div>
                                <span class="InputContainer" data-max="">
                                  <input class="CheckoutInput CheckoutInput--hasPlaceholderIcon Input Input--empty" autocomplete="email" autocorrect="off" spellcheck="false" autocapitalize="none" id="email" formControlName="Email" type="email" (focusout)="validateField('Email')" inputmode="email" placeholder="email@example.com" aria-invalid="false" aria-describedby="" data-1p-ignore="false" value=""></span>
                              </div>
                            </div>
                          </div>
                          <div class="FormFieldGroup-child FormFieldGroup-child--width-12 FormFieldGroup-childLeft FormFieldGroup-childRight FormFieldGroup-childBottom">
                            <div class="FormFieldInput">
                              <div class="PhoneNumberInput">
                                <div class="PhoneNumberInput-inputWrapper">
                                  <div class="CheckoutInputContainer">
                                    <div class="CheckoutInputContainer-placeholderIcon CheckoutInput--hasPhoneNumberCountryCodeSelect">
                                      <div class="PhoneNumberCountryCodeSelect-wrapper">
                                        <select class="PhoneNumberCountryCodeSelect-select" formControlName="DialCode">
                                          <optgroup label="A">
                                            <option value="+93">Afghanistan (+93)</option>
                                            <option value="+355">Albania (+355)</option>
                                            <option value="+213">Algeria (+213)</option>
                                            <option value="+376">Andorra (+376)</option>
                                            <option value="+244">Angola (+244)</option>
                                            <option value="+1">Anguilla (+1)</option>
                                            <option value="+1">Antigua &amp; Barbuda (+1)</option>
                                            <option value="+54">Argentina (+54)</option>
                                            <option value="+374">Armenia (+374)</option>
                                            <option value="+297">Aruba (+297)</option>
                                            <option value="+247">Ascension Island (+247)</option>
                                            <option value="+61">Australia (+61)</option>
                                            <option value="+43">Austria (+43)</option>
                                            <option value="+994">Azerbaijan (+994)</option>
                                          </optgroup>
                                          <optgroup label="Å">
                                            <option value="+358">Åland Islands (+358)</option>
                                          </optgroup>
                                          <optgroup label="B">
                                            <option value="+1">Bahamas (+1)</option>
                                            <option value="+973">Bahrain (+973)</option>
                                            <option value="+880">Bangladesh (+880)</option>
                                            <option value="+1">Barbados (+1)</option>
                                            <option value="+375">Belarus (+375)</option>
                                            <option value="+32">Belgium (+32)</option>
                                            <option value="+501">Belize (+501)</option>
                                            <option value="+229">Benin (+229)</option>
                                            <option value="+1">Bermuda (+1)</option>
                                            <option value="+975">Bhutan (+975)</option>
                                            <option value="+591">Bolivia (+591)</option>
                                            <option value="+387">Bosnia &amp; Herzegovina (+387)</option>
                                            <option value="+267">Botswana (+267)</option>
                                            <option value="+55">Brazil (+55)</option>
                                            <option value="+246">British Indian Ocean Territory (+246)</option>
                                            <option value="+1">British Virgin Islands (+1)</option>
                                            <option value="+673">Brunei (+673)</option>
                                            <option value="+359">Bulgaria (+359)</option>
                                            <option value="+226">Burkina Faso (+226)</option>
                                            <option value="+257">Burundi (+257)</option>
                                          </optgroup>
                                          <optgroup label="C">
                                            <option value="+855">Cambodia (+855)</option>
                                            <option value="+237">Cameroon (+237)</option>
                                            <option value="+1">Canada (+1)</option>
                                            <option value="+238">Cape Verde (+238)</option>
                                            <option value="+599">Caribbean Netherlands (+599)</option>
                                            <option value="+1">Cayman Islands (+1)</option>
                                            <option value="+236">Central African Republic (+236)</option>
                                            <option value="+235">Chad (+235)</option>
                                            <option value="+56">Chile (+56)</option>
                                            <option value="+86">China (+86)</option>
                                            <option value="+57">Colombia (+57)</option>
                                            <option value="+269">Comoros (+269)</option>
                                            <option value="+242">Congo - Brazzaville (+242)</option>
                                            <option value="+243">Congo - Kinshasa (+243)</option>
                                            <option value="+682">Cook Islands (+682)</option>
                                            <option value="+506">Costa Rica (+506)</option>
                                            <option value="+225">Côte d’Ivoire (+225)</option>
                                            <option value="+385">Croatia (+385)</option>
                                            <option value="+599">Curaçao (+599)</option>
                                            <option value="+357">Cyprus (+357)</option>
                                            <option value="+420">Czechia (+420)</option>
                                          </optgroup>
                                          <optgroup label="D">
                                            <option value="+45">Denmark (+45)</option>
                                            <option value="+253">Djibouti (+253)</option>
                                            <option value="+1">Dominica (+1)</option>
                                            <option value="+1">Dominican Republic (+1)</option>
                                          </optgroup>
                                          <optgroup label="E">
                                            <option value="+593">Ecuador (+593)</option>
                                            <option value="+20">Egypt (+20)</option>
                                            <option value="+503">El Salvador (+503)</option>
                                            <option value="+240">Equatorial Guinea (+240)</option>
                                            <option value="+291">Eritrea (+291)</option>
                                            <option value="+372">Estonia (+372)</option>
                                            <option value="+268">Eswatini (+268)</option>
                                            <option value="+251">Ethiopia (+251)</option>
                                          </optgroup>
                                          <optgroup label="F">
                                            <option value="+500">Falkland Islands (+500)</option>
                                            <option value="+298">Faroe Islands (+298)</option>
                                            <option value="+679">Fiji (+679)</option>
                                            <option value="+358">Finland (+358)</option>
                                            <option value="+33">France (+33)</option>
                                            <option value="+594">French Guiana (+594)</option>
                                            <option value="+689">French Polynesia (+689)</option>
                                          </optgroup>
                                          <optgroup label="G">
                                            <option value="+241">Gabon (+241)</option>
                                            <option value="+220">Gambia (+220)</option>
                                            <option value="+995">Georgia (+995)</option>
                                            <option value="+49">Germany (+49)</option>
                                            <option value="+233">Ghana (+233)</option>
                                            <option value="+350">Gibraltar (+350)</option>
                                            <option value="+30">Greece (+30)</option>
                                            <option value="+299">Greenland (+299)</option>
                                            <option value="+1">Grenada (+1)</option>
                                            <option value="+590">Guadeloupe (+590)</option>
                                            <option value="+1">Guam (+1)</option>
                                            <option value="+502">Guatemala (+502)</option>
                                            <option value="+44">Guernsey (+44)</option>
                                            <option value="+224">Guinea (+224)</option>
                                            <option value="+245">Guinea-Bissau (+245)</option>
                                            <option value="+592">Guyana (+592)</option>
                                          </optgroup>
                                          <optgroup label="H">
                                            <option value="+509">Haiti (+509)</option>
                                            <option value="+504">Honduras (+504)</option>
                                            <option value="+852">Hong Kong SAR China (+852)</option>
                                            <option value="+36">Hungary (+36)</option>
                                          </optgroup>
                                          <optgroup label="I">
                                            <option value="+354">Iceland (+354)</option>
                                            <option value="+91">India (+91)</option>
                                            <option value="+62">Indonesia (+62)</option>
                                            <option value="+964">Iraq (+964)</option>
                                            <option value="+353">Ireland (+353)</option>
                                            <option value="+44">Isle of Man (+44)</option>
                                            <option value="+972">Israel (+972)</option>
                                            <option value="+39">Italy (+39)</option>
                                          </optgroup>
                                          <optgroup label="J">
                                            <option value="+1">Jamaica (+1)</option>
                                            <option value="+81">Japan (+81)</option>
                                            <option value="+44">Jersey (+44)</option>
                                            <option value="+962">Jordan (+962)</option>
                                          </optgroup>
                                          <optgroup label="K">
                                            <option value="+7">Kazakhstan (+7)</option>
                                            <option value="+254">Kenya (+254)</option>
                                            <option value="+686">Kiribati (+686)</option>
                                            <option value="+383">Kosovo (+383)</option>
                                            <option value="+965">Kuwait (+965)</option>
                                            <option value="+996">Kyrgyzstan (+996)</option>
                                          </optgroup>
                                          <optgroup label="L">
                                            <option value="+856">Laos (+856)</option>
                                            <option value="+371">Latvia (+371)</option>
                                            <option value="+961">Lebanon (+961)</option>
                                            <option value="+266">Lesotho (+266)</option>
                                            <option value="+231">Liberia (+231)</option>
                                            <option value="+218">Libya (+218)</option>
                                            <option value="+423">Liechtenstein (+423)</option>
                                            <option value="+370">Lithuania (+370)</option>
                                            <option value="+352">Luxembourg (+352)</option>
                                          </optgroup>
                                          <optgroup label="M">
                                            <option value="+853">Macao SAR China (+853)</option>
                                            <option value="+261">Madagascar (+261)</option>
                                            <option value="+265">Malawi (+265)</option>
                                            <option value="+60">Malaysia (+60)</option>
                                            <option value="+960">Maldives (+960)</option>
                                            <option value="+223">Mali (+223)</option>
                                            <option value="+356">Malta (+356)</option>
                                            <option value="+596">Martinique (+596)</option>
                                            <option value="+222">Mauritania (+222)</option>
                                            <option value="+230">Mauritius (+230)</option>
                                            <option value="+262">Mayotte (+262)</option>
                                            <option value="+52">Mexico (+52)</option>
                                            <option value="+373">Moldova (+373)</option>
                                            <option value="+377">Monaco (+377)</option>
                                            <option value="+976">Mongolia (+976)</option>
                                            <option value="+382">Montenegro (+382)</option>
                                            <option value="+1">Montserrat (+1)</option>
                                            <option value="+212">Morocco (+212)</option>
                                            <option value="+258">Mozambique (+258)</option>
                                            <option value="+95">Myanmar (Burma) (+95)</option>
                                          </optgroup>
                                          <optgroup label="N">
                                            <option value="+264">Namibia (+264)</option>
                                            <option value="+674">Nauru (+674)</option>
                                            <option value="+977">Nepal (+977)</option>
                                            <option value="+31">Netherlands (+31)</option>
                                            <option value="+687">New Caledonia (+687)</option>
                                            <option value="+64">New Zealand (+64)</option>
                                            <option value="+505">Nicaragua (+505)</option>
                                            <option value="+227">Niger (+227)</option>
                                            <option value="+234">Nigeria (+234)</option>
                                            <option value="+683">Niue (+683)</option>
                                            <option value="+389">North Macedonia (+389)</option>
                                            <option value="+47">Norway (+47)</option>
                                          </optgroup>
                                          <optgroup label="O">
                                            <option value="+968">Oman (+968)</option>
                                          </optgroup>
                                          <optgroup label="P">
                                            <option value="+92">Pakistan (+92)</option>
                                            <option value="+970">Palestinian Territories (+970)</option>
                                            <option value="+507">Panama (+507)</option>
                                            <option value="+675">Papua New Guinea (+675)</option>
                                            <option value="+595">Paraguay (+595)</option>
                                            <option value="+51">Peru (+51)</option>
                                            <option value="+63">Philippines (+63)</option>
                                            <option value="+48">Poland (+48)</option>
                                            <option value="+351">Portugal (+351)</option>
                                            <option value="+1">Puerto Rico (+1)</option>
                                          </optgroup>
                                          <optgroup label="Q">
                                            <option value="+974">Qatar (+974)</option>
                                          </optgroup>
                                          <optgroup label="R">
                                            <option value="+262">Réunion (+262)</option>
                                            <option value="+40">Romania (+40)</option>
                                            <option value="+7">Russia (+7)</option>
                                            <option value="+250">Rwanda (+250)</option>
                                          </optgroup>
                                          <optgroup label="S">
                                            <option value="+685">Samoa (+685)</option>
                                            <option value="+378">San Marino (+378)</option>
                                            <option value="+239">São Tomé &amp; Príncipe (+239)</option>
                                            <option value="+966">Saudi Arabia (+966)</option>
                                            <option value="+221">Senegal (+221)</option>
                                            <option value="+381">Serbia (+381)</option>
                                            <option value="+248">Seychelles (+248)</option>
                                            <option value="+232">Sierra Leone (+232)</option>
                                            <option value="+65">Singapore (+65)</option>
                                            <option value="+1">Sint Maarten (+1)</option>
                                            <option value="+421">Slovakia (+421)</option>
                                            <option value="+386">Slovenia (+386)</option>
                                            <option value="+677">Solomon Islands (+677)</option>
                                            <option value="+252">Somalia (+252)</option>
                                            <option value="+27">South Africa (+27)</option>
                                            <option value="+82">South Korea (+82)</option>
                                            <option value="+211">South Sudan (+211)</option>
                                            <option value="+34">Spain (+34)</option>
                                            <option value="+94">Sri Lanka (+94)</option>
                                            <option value="+590">St Barthélemy (+590)</option>
                                            <option value="+290">St Helena (+290)</option>
                                            <option value="+1">St Kitts &amp; Nevis (+1)</option>
                                            <option value="+1">St Lucia (+1)</option>
                                            <option value="+590">St Martin (+590)</option>
                                            <option value="+508">St Pierre &amp; Miquelon (+508)</option>
                                            <option value="+1">St Vincent &amp; Grenadines (+1)</option>
                                            <option value="+597">Suriname (+597)</option>
                                            <option value="+47">Svalbard &amp; Jan Mayen (+47)</option>
                                            <option value="+46">Sweden (+46)</option>
                                            <option value="+41">Switzerland (+41)</option>
                                          </optgroup>
                                          <optgroup label="T">
                                            <option value="+886">Taiwan (+886)</option>
                                            <option value="+992">Tajikistan (+992)</option>
                                            <option value="+255">Tanzania (+255)</option>
                                            <option value="+66">Thailand (+66)</option>
                                            <option value="+670">Timor-Leste (+670)</option>
                                            <option value="+228">Togo (+228)</option>
                                            <option value="+690">Tokelau (+690)</option>
                                            <option value="+676">Tonga (+676)</option>
                                            <option value="+1">Trinidad &amp; Tobago (+1)</option>
                                            <option value="+290">Tristan da Cunha (+290)</option>
                                            <option value="+216">Tunisia (+216)</option>
                                            <option value="+90">Turkey (+90)</option>
                                            <option value="+993">Turkmenistan (+993)</option>
                                            <option value="+1">Turks &amp; Caicos Islands (+1)</option>
                                            <option value="+688">Tuvalu (+688)</option>
                                          </optgroup>
                                          <optgroup label="U">
                                            <option value="+256">Uganda (+256)</option>
                                            <option value="+380">Ukraine (+380)</option>
                                            <option value="+971">United Arab Emirates (+971)</option>
                                            <option value="+44">United Kingdom (+44)</option>
                                            <option value="+1">United States (+1)</option>
                                            <option value="+598">Uruguay (+598)</option>
                                            <option value="+998">Uzbekistan (+998)</option>
                                          </optgroup>
                                          <optgroup label="V">
                                            <option value="+678">Vanuatu (+678)</option>
                                            <option value="+39">Vatican City (+39)</option>
                                            <option value="+58">Venezuela (+58)</option>
                                            <option value="+84">Vietnam (+84)</option>
                                          </optgroup>
                                          <optgroup label="W">
                                            <option value="+681">Wallis &amp; Futuna (+681)</option>
                                            <option value="+212">Western Sahara (+212)</option>
                                          </optgroup>
                                          <optgroup label="Y">
                                            <option value="+967">Yemen (+967)</option>
                                          </optgroup>
                                          <optgroup label="Z">
                                            <option value="+260">Zambia (+260)</option>
                                            <option value="+263">Zimbabwe (+263)</option>
                                          </optgroup>
                                        </select>
                                      </div>
                                    </div>
                                    <span class="InputContainer" data-max=""><input class="CheckoutInput PhoneNumberInput-input CheckoutInput--hasPlaceholderIcon Input Input--empty" autocomplete="tel" autocorrect="off" spellcheck="false" id="phoneNumber" formControlName="PhoneNumber" (focusout)="validateField('PhoneNumber')" type="text" aria-required="true" placeholder="XXXXX XXXXX" aria-invalid="false" data-1p-ignore="false" value=""></span>
                                  </div>
                                  <div class="PhoneNumberInput-dynamicIcons">
                                    <div class="Tooltip-Context PhoneNumberInput-tooltipIconWrapper" style="padding: 12px; margin: -12px;">
                                      <svg class="InlineSVG Icon PhoneNumberInput-tooltipIcon Icon--sm Icon--square" focusable="false" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                        <path d="M6 12C9.28235 12 12 9.28235 12 6C12 2.72353 9.27647 0 5.99412 0C2.71765 0 0 2.72353 0 6C0 9.28235 2.72353 12 6 12ZM6 11C3.22353 11 1.00588 8.77647 1.00588 6C1.00588 3.22941 3.21765 1 5.99412 1C8.77059 1 10.9941 3.22941 11 6C11.0059 8.77647 8.77647 11 6 11ZM5.94706 3.90588C6.37647 3.90588 6.71177 3.56471 6.71177 3.14118C6.71177 2.71176 6.37647 2.37059 5.94706 2.37059C5.52353 2.37059 5.18235 2.71176 5.18235 3.14118C5.18235 3.56471 5.52353 3.90588 5.94706 3.90588ZM4.97059 9.23529H7.36471C7.60588 9.23529 7.79412 9.06471 7.79412 8.82353C7.79412 8.59412 7.60588 8.41177 7.36471 8.41177H6.63529V5.41765C6.63529 5.1 6.47647 4.88824 6.17647 4.88824H5.18235C4.94118 4.88824 4.75294 5.07059 4.75294 5.3C4.75294 5.54118 4.94118 5.71176 5.18235 5.71176H5.7V8.41177H4.97059C4.72941 8.41177 4.54118 8.59412 4.54118 8.82353C4.54118 9.06471 4.72941 9.23529 4.97059 9.23529Z" fill="currentColor"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                <div class="PhoneNumberInput-errorMessageAnimation" style="display: none; height: 0px; opacity: 0; pointer-events: none;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                              </div>
                            </div>
                          </div>
                          <div class="FieldError-container" style="opacity: 0; height: 0px; margin-top: 0px;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                        </div>
                        <div class="FieldError-container" style="opacity: 0; height: 0px; margin-top: 0px;"><span class="FieldError Text Text-color--red Text-fontSize--13"><span aria-hidden="true"></span></span></div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <!-- <div class="loading-bg justify-content-center" *ngIf="!isLoading"></div>
                <div class="loading justify-content-center" *ngIf="!isLoading"><img src="assets/images/loading.gif" /></div> -->
                <div class="flex-item width-12">
                  <h2 class="PaymentMethod-Heading Text Text-color--gray800 Text-fontSize--16 Text-fontWeight--500">Payment method</h2>
                </div>
                <div *ngIf="paymentMethods?.length">
                  <span class="Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">Select an Existing Payment Method</span>
                  <div *ngFor="let method of paymentMethods" class="paymentMethodRow">
                    <label>
                      <input 
                        type="radio" 
                        name="paymentMethod" 
                        [value]="method.Id" 
                        (change)="selectPaymentMethod(method.Id)">
                      <ng-container *ngIf="method.Card">
                        {{ toTitleCase(method.Card.Brand) }} ending in {{ method.Card.Last4 }}
                      </ng-container>
                      <ng-container *ngIf="method.UsBankAccount">
                        Bank Account ({{ method.UsBankAccount.BankName }}, ending in {{ method.UsBankAccount.Last4 }})
                      </ng-container>
                    </label>
                    
                  </div>
                  <div class="paymentMethodRow">
                    <label>
                      <input 
                        type="radio" 
                        name="paymentMethod" 
                        value="none" 
                        checked="checked"
                        (change)="selectPaymentMethod('none')">
                      <ng-container>
                        Add new payment method
                      </ng-container>
                    </label>
                  </div>
                </div>
                <div class="PaymentMethodForm" style="opacity: 1; height: auto;">
                  <div id="payment-element"></div>
                </div>
                <div class="PaymentForm-confirmPaymentContainer mt4 flex-item width-grow">
                  <div class="ConfirmPayment">
                    <div class="FormFieldCheckbox TermsOfServiceConsentCheckbox mb4">
                      <div class="CheckboxField">
                        <div class="Checkbox">
                          <div class="Checkbox-InputContainer">
                            <input id="termsOfServiceConsentCheckbox" name="termsOfServiceConsentCheckbox" type="checkbox" class="Checkbox-Input" (change)="onTermsOfServiceChange($event)" />
                          </div>
                          <div><label for="termsOfServiceConsentCheckbox"><span class="Checkbox-Label Text Text-color--gray600 Text-fontSize--13 Text-fontWeight--500">I agree to {{siteMeta.name}}.'s <a class="Link TermsOfServiceConsentCheckbox-customMessageLink Link--checkout--secondary" href="/terms" target="_blank" rel="noopener">Terms of Service</a> and <a class="Link TermsOfServiceConsentCheckbox-customMessageLink Link--checkout--secondary" href="/privacy" target="_blank" rel="noopener">Privacy Policy</a></span></label></div>
                        </div>
                      </div>
                    </div>
                    <div class="AnimateSinglePresence"></div>
                    <div class="flex-item width-12"></div>
                    <div class="flex-item width-12">
                      <button class="SubmitButton" type="submit" [disabled]="isButtonDisabled" data-testid="hosted-payment-submit-button" style="background-color: rgb(0, 116, 212); color: rgb(255, 255, 255);">
                        Pay
                      </button>
                    </div>
                    <div class="flex-item width-12">
                      <div class="ConfirmPayment-PostSubmit">
                        <div class="ConfirmTerms">
                          <div class="AnimateSinglePresence"></div>
                          <div class="AnimateSinglePresence">
                            <div class="AnimateSinglePresenceItem ConfirmPaymentTerms">
                              <div>
                                <div class="ConfirmTerms Text Text-color--gray600 Text-fontSize--13" data-testid="ConfirmPaymentTermsText">
                                  <div class="AnimateSinglePresence"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="AnimateSinglePresence"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </main>
        <footer class="App-Footer Footer">
          <div class="Footer-PoweredBy">
            <a class="Link Link--primary" href="https://tech.dropfitness.com" target="_blank" rel="noopener">
              <div class="Footer-PoweredBy-Text Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">
                Powered by 
                <span>
                  <img class="logo-img" [src]="'/assets/images/icon/drop_logo.png'" width="15" />
                </span>
              </div>
            </a>
          </div>
          <div class="CheckoutFooter-links flex-container direction-row">
            <a class="Link CheckoutFooter-link Link--checkout--secondary" [href]="siteMeta.termsUrl" target="_blank" rel="noopener"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Terms</span></a>
            <a class="Link CheckoutFooter-link Link--checkout--secondary" [href]="siteMeta.privacyPolicyUrl" target="_blank" rel="noopener"><span class="Text Text-color--gray400 Text-fontSize--12 Text-fontWeight--400">Privacy</span></a></div>
        </footer>
      </div>
    </div>
  </div>
</div>