import { Component, ElementRef, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { StripeClientService } from 'src/app/services/stripe-client.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { StripeService } from 'src/app/services/stripe.service';
import { AlertService } from 'src/app/services/alert.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'df-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  @ViewChild('cardElement') cardElement!: ElementRef;
  private elements!: StripeElements;
  private card!: StripeCardElement;

  @Input() public memberId : number = 0;
  @Output() passEntry: EventEmitter<boolean> = new EventEmitter();
  submitted:boolean  = false;
  errorMessage: string = "";
  successMessage: string = "";
  makeDefault: boolean = false;

  constructor(private stripeClientService: StripeClientService
    , public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private alertService: AlertService
    , private stripeService: StripeService
    ) {}

  ngOnInit(): void {
    this.stripeClientService.elements({ hidePostalCode: true}).then((elements: any) => {
      this.elements = elements;
      this.card = this.elements.create('card');
      this.card.mount(this.cardElement.nativeElement);

      // Hide the postal code field by applying the appropriate CSS class
      this.cardElement.nativeElement.classList.add('hide-postal-code');
    });
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    this.errorMessage = "";
    this.successMessage = "";
    const { token, error } = await this.stripeClientService.createToken(this.card);
    if (token) {
      // Send the token to your backend server to attach it to the customer
      this.stripeService.addCard(this.memberId, token.id, this.makeDefault).subscribe((result: boolean | string) => {
        console.log(result);
        console.log(typeof(result));
        
        if(typeof(result) != 'boolean')
        {
          this.alertService.error(result);
          // window.alert(result);
          this.closeModal(false);
          return;
        } else {
          this.submitted = false;
          this.successMessage = 'Card successfully attached to the customer!';
          this.closeModal(true);
          return;
        }
      }); 
    } else {
      this.errorMessage = error!.message!;
      console.error(error);
      // Handle the error
      this.submitted = false;
    }
  }

  closeModal(status:boolean = false) {
    this.passEntry.emit(status);
    this.activeModal.close(status);
  }
}
