declare var $: any;

import { v5 as uuidv5, NIL as NIL_UUID } from 'uuid';

import { environment } from 'src/environments/environment';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TermUnit } from 'src/app/models/enums';

const months = [  'January', 'February', 'March', 'April', 'May', 'June',   'July', 'August', 'September', 'October', 'November', 'December'];
  
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const Utils = {
  getTimestampFromDateTime,
  getTimestampFromDateTimeUTC,
  getLocalDateTime,
  formatDate,
  getStudioId,
  createSlug,
  reverseSlug,
  makeElementFloating,
  convertToQueryString,
  stringToGUID,
  hasStepCompleted,
  getEnumNameFromValue,
  newLineToBr,
  isNullOrEmpty,
  nextDate,
  getPreviousUnit,
  formatCurrency,
  toTitleCase
};

export function getTimestampFromDateTime(date: Date, time: string) : number
{
  date = new Date(date);
  var datetimeString: string = date.toISOString().substring(0, 10)+ "T" + time;
  const timestamp = Date.parse(datetimeString);
  return timestamp;
}

export function getTimestampFromDateTimeUTC(date: Date, time: string) : number
{
  var datetimeString: string = date.toISOString().substring(0, 10)+ "T" + time;
  date = new Date(Date.parse(datetimeString));
  let date_utc = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
    date.getUTCDate(), date.getUTCHours(),
    date.getUTCMinutes(), date.getUTCSeconds()));
  console.log(datetimeString, date_utc.toISOString());  
  return date_utc.getTime();
}

export function getLocalDateTime(date: Date): Date {
  const localDateString = date.toLocaleString('en-US', { timeZone: environment.facility[environment.submerchant].timezone });
  return new Date(localDateString);
}

export function formatDate(date: Date, pattern = 'yyyy-MM-dd HH:mm:ss Z'): string {
  const replacements: { [key: string]: string } = {
    yyyy: date.getFullYear().toString(),
    yy: (date.getFullYear() % 100).toString().padStart(2, '0'),
    MMMM: months[date.getMonth()],
    MMM: months[date.getMonth()].substring(0, 3),
    MM: (date.getMonth() + 1).toString().padStart(2, '0'),
    M: (date.getMonth() + 1).toString(),
    dddd: weekdays[date.getDay()],
    ddd: weekdays[date.getDay()].substring(0, 3),
    ddy: date.getDate().toString().padStart(2, '0'),
    dy: date.getDate().toString(),
    dd: date.getDate().toString().padStart(2, '0'),
    d: date.getDate().toString(),
    HH: date.getHours().toString().padStart(2, '0'),
    H: date.getHours().toString(),
    hh: (date.getHours() % 12 || date.getHours()).toString().padStart(2, '0'),
    h: (date.getHours() % 12 || date.getHours()).toString(),
    mm: date.getMinutes().toString().padStart(2, '0'),
    m: date.getMinutes().toString(),
    ss: date.getSeconds().toString().padStart(2, '0'),
    s: date.getSeconds().toString(),
    Z: date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
    x: date.getHours() < 12 ? 'am' : 'pm',
    X: date.getHours() < 12 ? 'AM' : 'PM',
  };
  const suffixes: { [key: string]: string } = {
    '1': 'st',
    '2': 'nd',
    '3': 'rd',
    '21': 'st',
    '22': 'nd',
    '23': 'rd',
    '31': 'st',
  };
  const formattedString = pattern.replace(/(\w+)(\W)?/g, (_, key, separator) => {
    if (key in replacements) {
      let replacedValue = replacements[key];
      if (key === 'd') {
        const day = date.getDate().toString();
        replacedValue += '';
      }
      if (key === 'dd') {
        const day = date.getDate().toString().padStart(2, '0');
        replacedValue += '';
      }
      if (key === 'dy') {
        const day = date.getDate().toString();
        replacedValue += suffixes[day] || 'th';
      }
      if (key === 'ddy') {
        const day = date.getDate().toString().padStart(2, '0');
        replacedValue += suffixes[day] || 'th';
      }
      if (key === 'm') {
        const month = (date.getMonth() + 1).toString();
        replacedValue = replacedValue.replace(key, month);
      }
      if (key === 'mm') {
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        replacedValue = replacedValue.replace(key, month);
      }
      if (key === 'x' || key === 'X') {
        const replacement = key === 'x' ? replacements['x'] : replacements['X'];
        replacedValue = replacedValue.replace(key, replacement);
      }
      return replacedValue + (separator || '');
    }
    return key;
  });
  return formattedString;
}

export function getStudioId(studioSlug: string): number {
  switch (studioSlug.toLowerCase()) {
    case 'fhitting-room': return 1;
    case 'humming-puppy': return 2;
    case 'mhrc': return 3;
    case 'physique-57': return 4;
    case 'turf-room': return 5;
    default:  return 0;
  }
}

export function createSlug(name: string) {
  // Convert name to lowercase and replace spaces with hyphens
  let slug = name.toLowerCase().replace(/\s+/g, '-');

  // Remove any special characters
  slug = slug.replace(/[^\w\-]/g, '');

  // Replace any remaining special characters with a hyphen
  slug = slug.replace(/\-+/g, '-');

  // Trim any leading or trailing hyphens
  slug = slug.replace(/^\-+|\-+$/g, '');

  return slug;
}

export function reverseSlug(slug: string) {
  // Replace any duplicate hyphens with a space
  let name = slug.replace(/\-{2,}/g, ' ');

  // Replace any remaining hyphens with a space
  name = name.replace(/\-/g, ' ');

  // Capitalize the first letter of each word
  name = name.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });

  return name;
}

export function makeElementFloating(referenceElem: string, elem: string)
{
  var windowTop = $(window).scrollTop();
  var windowBottom = windowTop + $(window).height();
  var divTop = $(referenceElem).offset().top;
  var divBottom = divTop + $(referenceElem).height();
  if ((divTop <= windowBottom) && (divBottom >= windowTop)) {
    $(elem).css('position', 'relative');
  } else {
    $(elem).css('position', 'fixed');
  }
}

export function stringToGUID(input: string): string {
  // Generate a new GUID using the input string as the namespace
  const guid = uuidv5(input, NIL_UUID);

  return guid;
}

export function hasStepCompleted(input: string, token: string, delimiter: string = "|"): boolean {
  const explodedArray = input.split(delimiter);
  return explodedArray.includes(token);
}

export function getEnumNameFromValue(enumType: any, value: number): string {
    return enumType[value] || '';
}

export function newLineToBr(text: string, sanitizer: DomSanitizer): SafeHtml {
  const html = text.replace(/\n/g, '<br/>');
  return sanitizer.bypassSecurityTrustHtml(html);
}

export function isNullOrEmpty(inputString: string | null | undefined): boolean {
  return inputString == null || inputString == undefined || inputString.trim() == '';
}

export function nextDate(startDate: Date, duration: number, unit: TermUnit): Date {
  let finalDate = new Date(startDate);

  switch (unit) {
      case TermUnit.Day:
          finalDate.setDate(startDate.getDate() + duration);
          break;
      case TermUnit.Week:
          finalDate.setDate(startDate.getDate() + duration * 7);
          break;
      case TermUnit.Month:
          finalDate.setMonth(startDate.getMonth() + duration);
          const lastDayOfMonth = new Date(finalDate.getFullYear(), finalDate.getMonth() + 1, 0).getDate();
          finalDate.setDate(Math.min(lastDayOfMonth, startDate.getDate()));
          break;
      case TermUnit.Year:
          finalDate.setFullYear(startDate.getFullYear() + duration);
          break;
      case TermUnit.Unset:
          break;
      default:
          finalDate.setMonth(startDate.getMonth() + duration);
          break;
  }
  return finalDate;
}

export function getPreviousUnit(unit: TermUnit): TermUnit {
  if (unit === TermUnit.Day) return TermUnit.Unset; // Since there's no enum before Day
  return unit - 1;
}

export function convertToQueryString(obj: any, parentKey?: string): string {
  const queryStringArray: string[] = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      const newKey = parentKey ? `${parentKey}.${key}` : key;  // Adjust newKey format

      if (value instanceof Array) {
        for (let i = 0; i < value.length; i++) {
          const arrayKey = `${newKey}[${i}]`;
          const arrayValue = value[i];
          queryStringArray.push(convertToQueryString(arrayValue, arrayKey));
        }
      } else if (typeof value === 'object' && value !== null) {
        queryStringArray.push(convertToQueryString(value, newKey));
      } else {
        queryStringArray.push(`${newKey}=${encodeURIComponent(value)}`);
      }
    }
  }

  return queryStringArray.join('&');
}

export function formatCurrency(amount: number, currency: string = 'USD', locale: string = 'en-US'): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(amount);
}

export function toTitleCase(input: string): string {
  return input
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}