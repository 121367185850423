<df-promo-banner *ngIf="memberId == 0 && bannerObj" id="promoBanner" [banner]="bannerObj"></df-promo-banner>
<div class="prices-area ptb-lg-100">
	<div class="container px-0">
    <app-alert></app-alert>
    <!-- <div class="ph-item" *ngIf="!sessionDetails"> -->
      <div class="ph-item" *ngIf="!currencyMeta">
        <div class="ph-col-3">
            <div class="ph-row">
              <div class="ph-col-10 big"></div>
              <div class="ph-col-2 empty big"></div>
              <div class="ph-col-10"></div>
            </div>
            <div class="ph-row">
              <div class="ph-col-10 big"></div>
              <div class="ph-col-2 empty big"></div>
              <div class="ph-col-10"></div>
            </div>
            <div class="ph-row">
              <div class="ph-col-10 big"></div>
              <div class="ph-col-2 empty big"></div>
              <div class="ph-col-10"></div>
            </div>
        </div>
        <div class="ph-col-9">
          <div class="ph-row">
            <div class="ph-col-12"></div>
            <div class="ph-col-6"></div>
            <div class="ph-col-8"></div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-5 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-5 big">
              <div class="ph-picture"></div>
            </div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-5 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-5 big">
              <div class="ph-picture"></div>
            </div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12 big empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12"></div>
            <div class="ph-col-6"></div>
            <div class="ph-col-8"></div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12 big empty"></div>
          </div>
          <div class="ph-row">
            <div class="ph-col-12"></div>
            <div class="ph-col-6"></div>
            <div class="ph-col-8"></div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
            <div class="ph-col-1 empty big"></div>
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
          </div>
          <div class="ph-row auto-height">
            <div class="ph-col-3 big">
              <div class="ph-picture"></div>
            </div>
          </div>
        </div>
    </div>
    
    <div class="row m-0" *ngIf="currencyMeta">
			<div class="col-lg-3 col-md-12 mt-3">
        <div class="navbar-left">
          <div *ngIf="locations && locations.length" class="location-info">
            <p class="info">Location</p>
              <select matInput matTooltip="Location" (selectionChange)="onLocationChange($event)" [(ngModel)]="locationId" class="location-dropdown">
                <option *ngFor="let location of locations" [value]="location.Id">
                  {{ location.Name }}
                </option>
              </select>
            <!-- <h3 class="heading">Montvale, NJ</h3> -->
          </div>
          <div class="categories-wrapper">
            <ul class="categories">
              <ng-container *ngFor="let category of currencyMeta.Categories">
              <li  
                *ngIf="category.State == 1"
                class="category-name" 
                [ngClass]="{ 'active': activeScrollCategoryId == category.Id}"
                (click)="goToCategory(category.Id)">
                {{category.Name}}
              </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-12 mt-3 content-container">
        <div *ngFor="let categoryMenuPack of categoryMenuPacks" class="category-wraper subscription" id="category-{{categoryMenuPack.Category.Id}}">
          <h3 class="title">
            {{categoryMenuPack.Category.Name}}
            <span class="tag">
              {{currencyMeta.Discount.Name}}
            </span>
          </h3>
          <p class="description">
            {{categoryMenuPack.Category.Description}}
          </p>
          <div class="row pack-list">
            <ng-container *ngFor="let menuPack of categoryMenuPack.MenuPacks">
              <div class="col-lg-6 col-md-12" *ngIf="categoryMenuPack.Category.Type == 2 || categoryMenuPack.Category.Type == 3">
                <div class="pack-details subscription" [style.background-image]="'url(' + getPackImage(menuPack.Id) + ')'">
                  <h4 class="pack-name" [innerHTML]="getMenuPackNameById(menuPack.Id)"></h4>
                  <p class="price"><span class="decoy-price" *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">${{menuPack.DecoyPrice/100}}</span><span *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{menuPack.Price/100}}</span> {{getTermDuration(menuPack.Id)}}</p>
                  <p class="description" [innerHTML]="getPackDescriptionById(menuPack.PackId)"></p>
                  <button class="buy-btn" (click)="openBenefitsPopup(menuPack.Id)" [disabled]="selected">select</button>
                </div>
              </div>
              <div class="col-lg-4 col-6" *ngIf="categoryMenuPack.Category.Type == 1 || categoryMenuPack.Category.Type == 3">
                <div class="pack-details pack">
                  <h4 class="pack-name" [innerHTML]="getMenuPackNameById(menuPack.Id)"></h4>
                  <p class="price"><span class="decoy-price" *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">${{menuPack.DecoyPrice/100}}</span><span *ngIf="menuPack.DecoyPrice > 0 && menuPack.DecoyPrice != menuPack.Price">&nbsp; | &nbsp;</span><span class="actual-price">${{menuPack.Price/100}}</span></p>
                  <br/>
                  <br/>
                  <button class="buy-btn" (click)="openBenefitsPopup(menuPack.Id)" [disabled]="selected">select</button>
                  <p class="expiry-label">{{getPackExpiryLabel(menuPack.Id)}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
	</div>
</div>

<df-login #login [title]="loginTitle" [loginText]="loginDescription" (loggedIn)="loggedIn($event)"></df-login>