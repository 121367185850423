declare var $: any;
//system
import { Component, ViewChild, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

//third-party
import { createEvent, DateArray, EventAttributes } from 'ics';
import { v4 as uuidv4 } from 'uuid';

//services
import { LogService } from 'src/app/services/common/log.service';
import { StudioService } from 'src/app/services/studio.service';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { InviteCodeService } from 'src/app/services/invite-code.service';

//components
import { LoginComponent } from 'src/app/components/df-login/df-login.component';
import { InviteComponent } from 'src/app/components/invite/invite.component';

//interfaces
import { MemberModel } from 'src/app/models/member';
import { SessionDetails, ClassMember } from 'src/app/models/studio/session-details';
import { ClassMemberModel, ClassBookingRequest } from 'src/app/models/studio/class-booking';
import { OpenGraphMetadata, InviteCode, InviteCodeGenerateRequest, InviteDialogModel, InviteCodeRedeemRequest } from 'src/app/models/invite-code';

//environment
import { environment } from 'src/environments/environment';

//utils
import { Utils } from 'src/app/services/common/utils';
import { UserType } from 'src/app/models/enums';
import { InviteCodeIntent } from 'src/app/models/enums';
import { AlertService } from 'src/app/services/alert.service';

declare const google: any;

@Component({
  selector: 'app-confirm-reservation',
  templateUrl: './confirm-reservation.component.html',
  styleUrls: ['./confirm-reservation.component.scss']
})
export class ConfirmReservationComponent implements OnInit {
  @ViewChild('login') loginPopup!: LoginComponent;
  address         : string = '';
  directionUrl    : string = '';
  sessionTiming   : string = '';
  classMember!    : ClassMemberModel;
  person          : any;
  submitted       : boolean  = false;
  inviteSubmitted : boolean  = false;
  inviteCode!     : InviteCode;
  currentMember!  : MemberModel;
  inviteDialogModel!: InviteDialogModel;
  openGraphMetadata!: OpenGraphMetadata;

  @Input() public sessionDetails! : SessionDetails;
  @Input() public selectedSpots! : number[];
  @Output() passEntry: EventEmitter<ClassMemberModel> = new EventEmitter();


  constructor(
    public activeModal: NgbActiveModal
    , private router: Router
    , private location: Location
    , private modalService: NgbModal
    , private logService: LogService
    , private studioService: StudioService
    , private userService: UserService
    , private authService: AuthService
    , private inviteCodeService: InviteCodeService
    , private alertService: AlertService
  ) 
  {
    
  }

  async ngOnInit() {
    this.address = await this.getAddress();
    this.directionUrl = this.getDirectionUrl();
    this.currentMember = this.authService.getLoggedInMember();
    this.person = this.userService.getUser();
    var localSessionTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
    var localSessionDateTime: Date = new Date(localSessionTimestamp);
    this.sessionTiming = Utils.formatDate(localSessionDateTime, "dddd, MMM d") + " at " + Utils.formatDate(localSessionDateTime, "h:mm X");
  }

  goBack() {
    this.activeModal.close(null);
  }

  closeModal() {
    this.passEntry.emit(this.classMember);
    this.activeModal.close(this.classMember);
  }

  reserveSpot() {
    this.logService.debug("reserve spot");
    this.submitted = true;
    var isLoggedIn : boolean = this.authService.isLoggedIn();
    if(!isLoggedIn)
    {
      this.loginPopup.open(this.sessionDetails.Id);
      return;
    }

    var member: any = this.userService.getMember();
      const bookingRequest: ClassBookingRequest = {
        SessionId: this.sessionDetails.Id,
        MemberId: member.Id,
        Spot: this.selectedSpots[0]
      };

      this.studioService.bookSpot(bookingRequest).subscribe((data: ClassMemberModel | string) => {
        if(typeof data === 'string') {
          this.logService.debug("data", data);
          if(data == "NOT_ENOUGH_CREDITS")
          {
            this.alertService.error("You do not have enough credits to book a class.");
            this.submitted = false;
            this.closeModal();
            return;
          }

          if(data == "Spot is already taken.")
          {
            this.alertService.error("Spot is already taken.");
            this.submitted = false;
            this.closeModal();
            return;
          }

          if(data == "Class is cancelled.")
          {
            this.alertService.error("Class is cancelled.");
            this.submitted = false;
            this.closeModal();
            return;
          }

          if(data.startsWith("Schedule conflict detected."))
          {
            // window.alert(data);
            console.error(data);
            this.alertService.error('Schedule conflict.');
            this.submitted = false;
            this.closeModal();
            return;
          }
        } else {
          this.logService.debug("typeof data", typeof data);
          this.classMember = data;
          this.logService.debug("bookSpot response", this.classMember);
          this.submitted = false;
          this.closeModal();
        }
      });
  }

  async inviteFriends() {
    this.inviteSubmitted = true;

    this.inviteCodeService.generateInviteCode(new InviteCodeGenerateRequest(
      UserType.Member,
      this.currentMember.Id,
      InviteCodeIntent.ClassSession,
      this.sessionDetails.Id,
      -1
    )).subscribe(async (data : InviteCode) => {
      this.inviteCode = data;

      this.openGraphMetadata = await this.inviteCodeService.getOpenGraphMetadata(this.inviteCode.Code);

      //open invite friends popup
      const modalRef =this.modalService.open(InviteComponent, {
        ariaLabelledBy: 'modal-basic-title', 
        // size: 'lg',
        centered: true,
        fullscreen: window.innerWidth <= 768,
        // windowClass: 'custom-class'
      });

      var localSessionTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
      var localSessionDateTime: Date = new Date(localSessionTimestamp);
      var title = this.sessionDetails.Studio.Name + " - " + this.sessionDetails.Class.Name + " at " + environment.facility[environment.submerchant].name + " " + Utils.formatDate(localSessionDateTime, "dddd, MMM d") + " at " + Utils.formatDate(localSessionDateTime, "h:mm X");
      var emailTitle = 'Hey! Join me at ' + this.sessionDetails.Studio.Name + '!🙌';
      var emailBody = 'Hey! Join me for ' + this.sessionDetails.Class.Name + ' at ' + this.sessionDetails.Studio.Name + ' on ' + Utils.formatDate(localSessionDateTime, "dddd, MMM d") + ' at ' + Utils.formatDate(localSessionDateTime, "h:mm X") + ' inside ' + environment.facility[environment.submerchant].name + '. Use the link below to book your spot! ' + this.getSharerUrl();
      console.log("emailBody", emailBody);
      //Passing InviteCode and InviteDialogModel to popup
      modalRef.componentInstance.inviteCode = this.inviteCode;
      modalRef.componentInstance.inviteDialogModel = new InviteDialogModel(
        this.openGraphMetadata.Title,
        this.openGraphMetadata.Description,
        this.openGraphMetadata.ImageUrl,
        this.openGraphMetadata.Url,
        emailTitle,
        emailBody
      );

      //Retrieving result from popup
      modalRef.result.then((result: void) => {
          this.logService.debug("invite popup closed:");
          this.inviteSubmitted = false;
      });
    });

    
  }

  getSharerUrl() : string
  {
    return environment.facility[environment.submerchant].sharerBaseUrl + "share?invite_code=" + this.inviteCode.Code + "&location_id=" + 1
  }

  getDirectionUrl(): string {
    return `https://www.google.com/maps/dir/?api=1&destination=${this.sessionDetails.Class.Latitude},${this.sessionDetails.Class.Longitude}`;
  }

  generateICS() {
    var localSessionStartTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.StartTime);
    var localSessionEndTimestamp: number = Utils.getTimestampFromDateTime(this.sessionDetails.StartDate, this.sessionDetails.EndTime);

    var date = new Date(localSessionStartTimestamp);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    const startDateTimeArray: DateArray = [year, month, day, hour, minute];

    date = new Date(localSessionEndTimestamp);
    year = date.getFullYear();
    month = date.getMonth();
    day = date.getDate();
    hour = date.getHours();
    minute = date.getMinutes();
    const endDateTimeArray: DateArray = [year, month, day, hour, minute];

    const event: EventAttributes = {
      start: startDateTimeArray, // year, month (0-11), day, hour, minute
      end: endDateTimeArray,
      title: this.sessionDetails.Class.Name + " at " + this.sessionDetails.Studio.Name,
      description: this.sessionDetails.Class.Name + " at " + this.sessionDetails.Studio.Name,
      location: this.address,
      url: window.location.origin + this.router.url,
      status: 'CONFIRMED',
      organizer: {
        name: this.sessionDetails.InstructorName,
        email: environment.facility[environment.submerchant].supportEmail
      }
    };
    
    createEvent(event, (error, value) => {
      if (error) {
        this.logService.error(error);
        return;
      }
  
      const blob = new Blob([value], { type: 'text/calendar' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'df-event-' + uuidv4() + '.ics';
      link.click();
    });
  }
  
  
  

  async getAddress(): Promise<string> {
    var address : string = "";
    const geocoder = new google.maps.Geocoder();
    const latLng = new google.maps.LatLng(environment.facility[environment.submerchant].googleMap.latitude, environment.facility[environment.submerchant].googleMap.longitude);

    return new Promise<string>((resolve, reject) => {
      geocoder.geocode({ location: latLng }, (results: any, status: any) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address.replace("Inside ", "");
            resolve(address);
          } else {
            this.logService.debug("Geocoder: No results found");
            reject("No results found");
          }
        } else {
          this.logService.debug("Geocoder failed due to: " + status);
          reject(status);
        }
      });
    });
  }
}
