declare var $: any;
//system
import { Component, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';

//components

@Component({
  selector: 'harlem-webpage',
  templateUrl: './harlem-webpage.component.html',
  styleUrls: ['./harlem-webpage.component.scss']
})

export class HarlemWebpageComponent implements OnInit, OnDestroy {
  public htmlContent: string = '';

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) 
  { 
    
  }

  ngOnInit(): void {
    window.addEventListener('message', this.handleMessageEvent);
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.handleMessageEvent);
  }

  private handleMessageEvent = (event: MessageEvent): void => {
    if (event.data && event.data.frameHeight) {
      const height = event.data.frameHeight;
      const wrapper = document.getElementById('frame-wrapper');
      if (wrapper) {
        wrapper.style.height = `${height}px`;
      }
    }
  }
}