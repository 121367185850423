<div class="classes-area pt-4 ptb-lg-100">
	<div class="container px-0">
        <app-alert></app-alert>
        <div class="ph-item" *ngIf="!sessionDetails">
            <div class="ph-col-8">
                <div class="ph-picture"></div>
                <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-12"></div>
                </div>
            </div>
            <div class="ph-col-4">
                <div class="ph-picture"></div>
                <div class="ph-row">
                    <div class="ph-col-6 big"></div>
                    <div class="ph-col-4 empty big"></div>
                    <div class="ph-col-2 big"></div>
                </div>
            </div>
        </div>
		<div class="row m-0" *ngIf="sessionDetails">
			<div class="col-lg-7 col-md-12 offset-lg-1 mt-3 px-0">
				<div class="banner-wrapper">
                    <button type="button" class="btn btn-light back-button" title="Back" (click)="goBack()">
                    </button>

                    <button *ngIf="currentMember" type="button" class="btn btn-light invite-friend-button" title="Share" (click)="inviteFriends()">
                    </button>
                    <div class="banner-image" [ngStyle]="{'background-image': 'url(' + sessionDetails?.ClassPhoto + ')'}" [title]="sessionDetails?.ClassName"></div>
                </div>
                <div class="studio-name-photo-wrapper px-3">
                    <div class="row">
                        <div class="col-7">
                            <img [src]="sessionDetails?.StudioNamePhoto" [alt]="sessionDetails?.Studio?.Name" [title]="sessionDetails?.Studio?.Name" />
                        </div>
                        <div class="col-5 session-timing">
                            <p>{{dayOfWeek}}, {{month}} {{dayOfMonth}}</p>
                            <p>{{startTime}} - {{endTime}}</p>
                        </div>
                    </div>
                </div>
                
                <h3 class="mb-3 mt-3 section-heading px-3">Class Details</h3>
                
                <div class="instructor-details-wrapper">
                    <div *ngIf="sessionDetails.InstructorDefaultAvatar" class="instructor-photo-wrapper">
                        <img [src]="sessionDetails.InstructorDefaultAvatar" [alt]="sessionDetails.InstructorName" class="rounded-circle instructor-photo" />
                    </div>
                    <div class="instructor-details">
                        <p class="instructor-label">Instructor</p>
                        <p class="instructor-name">{{sessionDetails.InstructorName}}</p>
                    </div>
                </div>
                <div class="class-description px-3">
                    <p>
                        <span>{{sessionDetails.Class.Name}}</span> - 
                        <span>{{sessionDetails.Class.Description}}</span>
                    </p>
                </div>

                <h3 class="mb-3 mt-3 section-heading px-3">Amenities</h3>
                <div class="amenities-wrapper px-3">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_shower.svg" alt="Showers" title="Showers" />
                                <p>Showers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_locker_rooms.svg" alt="Locker Rooms" title="Locker Rooms" />
                                <p>Locker Rooms</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_sauna.svg" alt="Sauna" title="Sauna" />
                                <p>Sauna</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_juice_bar.svg" alt="Juice Bar" title="Juice Bar" />
                                <p>Juice Bar</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_towel_service.svg" alt="Towel Service" title="Towel Service" />
                                <p>Towel Service</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_blow_dryer.svg" alt="Blow Dryers" title="Blow Dryers" />
                                <p>Blow Dryers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_beauty_products.svg" alt="Beauty Products" title="Beauty Products" />
                                <p>Beauty Products</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_kids_club.svg" alt="Kids Club" title="Kids Club" />
                                <p>Kids Club</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_locker_chargers.svg" alt="Locker Chargers" title="Locker Chargers" />
                                <p>Locker Chargers</p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 p-0">
                            <div class="amenity">
                                <img src="/assets/images/svg/icon_cold_plunge_shower.svg" alt="Cold Plunge Showers" title="Cold Plunge Showers" />
                                <p>Cold Plunge Showers</p>
                            </div>
                        </div>
                    </div>  
                </div>
			</div>
			<div class="col-lg-3 col-md-12 mt-3 map-section">
                <h3 class="m-3 section-heading">Location
                    <a [href]="directionUrl" target="_blank" class="direction-link">Get Direction</a>
                </h3>
				<app-map [latitude]="sessionDetails.Class.Latitude" [longitude]="sessionDetails.Class.Longitude" [details]="sessionDetails.Class.Details"></app-map>
			</div>
		</div>
	</div>
    <div class="floating-footer" *ngIf="sessionDetails">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-sm-12 offset-lg-1 p-lg-2 " style="position: relative;">
                    <p class="spots-left-label">{{spotsLeftText()}}</p>
                    <button *ngIf="isInFuture() && spotsLeft === 0 && !isInWaitlist && !isSessionBooked" [ngClass]="{'btn':true, 'btn-dark': spotsLeft!==0, 'btn-outline-dark': spotsLeft===0, 'reserve-spot-button':true}" (click)="JoinWaitlistClicked()">
                        JOIN WAITLIST
                    </button>
                    <button *ngIf="isInFuture() && spotsLeft === 0 && isInWaitlist && !isSessionBooked" [ngClass]="{'btn':true, 'btn-dark': spotsLeft!==0, 'btn-outline-dark': spotsLeft===0, 'reserve-spot-button':true}" (click)="CancelWaitlistClicked()">
                        CANCEL WAITLIST
                    </button>
                    <button *ngIf="isInFuture() && spotsLeft > 0 && !isSessionBooked" [ngClass]="{'btn':true, 'btn-dark': spotsLeft!==0, 'btn-outline-dark': spotsLeft===0, 'reserve-spot-button':true}" (click)="openSpotMapClicked()">
                        RESERVE
                    </button>
                    <button *ngIf="isInFuture() && isSessionBooked" class="btn btn-light cancel-spot-button" (click)="cancelReservation(classMember.Id)">
                        CANCEL RESERVATION
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<df-login #login title="Log into your account" (loggedIn)="loggedIn($event)"></df-login>
<df-download-app #download (click)="dlPopupClicked()"></df-download-app>