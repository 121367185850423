import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

//models
import { Utils } from 'src/app/services/common/utils';

//services
import { HttpService, HttpServiceConfig } from 'src/app/services/common/http.service';

//environment
import { environment } from 'src/environments/environment';
import { Category, Rate, Region } from 'src/app/models/tax';
import { TaxCategoryGrid, TaxRateGrid, TaxRegionGrid } from 'src/app/models/grids';

@Injectable({
  providedIn: 'root'
})
export class TaxService {

  constructor(private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  /**  Category */
  fetchCategory(id: number) : Observable<Category> {
    return this.httpService.get<Category>(`Tax/Category/${id}`);
  }

  getCategories() : Observable<Category[]> {
    return this.httpService.get<Category[]>(`Tax/Category`);
  }

  getPreviousCategory(id: number) : Observable<Category> {
    return this.httpService.get<Category>(`Tax/Category/Before/${id}`);
  }

  getNextCategory(id: number) : Observable<Category> {
    return this.httpService.get<Category>(`Tax/Category/After/${id}`);
  }

  duplicateCategory(id: number) : Observable<Category> {
    return this.httpService.put<Category>(`Tax/Category/Duplicate/${id}`, null);
  }

  saveCategory(dto: Partial<Category>): Observable<Category> {
    return this.httpService.post<Category>(`Tax/Category/Save`, dto);
  }

  /**  Region */
  fetchRegion(id: number) : Observable<Region> {
    return this.httpService.get<Region>(`Tax/Region/${id}`);
  }

  getRegion(id: number) : Promise<Region> {
    return this.httpService.getPromise<Region>(`Tax/Region/${id}`);
  }

  getRegions() : Observable<Region[]> {
    return this.httpService.get<Region[]>(`Tax/Region`);
  }

  getPreviousRegion(id: number) : Observable<Region> {
    return this.httpService.get<Region>(`Tax/Region/Before/${id}`);
  }

  getNextRegion(id: number) : Observable<Region> {
    return this.httpService.get<Region>(`Tax/Region/After/${id}`);
  }

  duplicateRegion(id: number) : Observable<Region> {
    return this.httpService.put<Region>(`Tax/Region/Duplicate/${id}`, null);
  }

  saveRegion(dto: Partial<Region>): Observable<Region> {
    return this.httpService.post<Region>(`Tax/Region/Save`, dto);
  }

  /**  Rate */
  fetchRate(id: number) : Observable<Rate> {
    return this.httpService.get<Rate>(`Tax/Rate/${id}`);
  }

  getPreviousRate(id: number) : Observable<Rate> {
    return this.httpService.get<Rate>(`Tax/Rate/Before/${id}`);
  }

  getNextRate(id: number) : Observable<Rate> {
    return this.httpService.get<Rate>(`Tax/Rate/After/${id}`);
  }

  duplicateRate(id: number) : Observable<Rate> {
    return this.httpService.put<Rate>(`Tax/Rate/Duplicate/${id}`, null);
  }

  saveRate(dto: Partial<Rate>): Observable<TaxRateGrid> {
    return this.httpService.post<TaxRateGrid>(`Tax/Rate/Save`, dto);
  }
}
