//system
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService, HttpServiceConfig } from './common/http.service';
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http';

//services
import { LogService } from './common/log.service';

//environment
import { environment } from 'src/environments/environment';

//models
import { OpenGraphMetadata, InviteCode, InviteCodeGenerateRequest, InviteCodeRedeemRequest } from 'src/app/models/invite-code';
import { InterestedPerson } from '../models/common-models';

@Injectable({
  providedIn: 'root'
})
export class InterestedPersonService {

  constructor(private logService: LogService, private httpService: HttpService) { 
    const config: HttpServiceConfig = {
      baseUrl: `${environment.facility[environment.submerchant].facilityUrl}api/v${environment.facility[environment.submerchant].facilityApiVersion}`
    };
    this.httpService.configure(config);
  }

  saveInterestedPerson(request: Partial<InterestedPerson>): Observable<InterestedPerson> {
    return this.httpService.post<InterestedPerson>(`InterestedPerson`, request);
  }
}
