import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { loadStripe, Stripe, StripeCardElement, StripeElement, StripeElements } from '@stripe/stripe-js';
import { throwError } from 'rxjs';
import { Location, PaymentIntentResponse, PaymentRequest, SiteMeta } from 'src/app/models/common-models';
import { TermUnit } from 'src/app/models/enums';
import { MenuPack, Pack } from 'src/app/models/meta';
import { Region } from 'src/app/models/tax';
import { LogService } from 'src/app/services/common/log.service';
import { Utils } from 'src/app/services/common/utils';
import { StripeService } from 'src/app/services/stripe.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-form',
  standalone: true,
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  imports: [RouterModule, FormsModule, ReactiveFormsModule, NgIf, NgFor],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentFormComponent implements OnInit {
  
  @Input() public locationId : number;
  @Input() public menuPack : MenuPack;
  @Input() public pack : Pack;
  @Input() public location : Location;
  @Input() public taxRegion : Region;
  @Input() public siteMeta : SiteMeta;

  isLoading = true;
  paymentIntentCreated = false;
  paymentDetailsAdded = false;
  submitted: boolean = false;
  
  stripe!: Stripe | null;
  elements!: StripeElements;
  cardElement!: StripeCardElement;
  paymentElement!: StripeElement | null;
  errorMessage: string | null = null;

  taxAmount: number = 0;
  agreedTermsOfService: boolean = false;

  paymentForm!: FormGroup;
  paymentRequest: PaymentRequest = { MenuPackId: 0, Amount: 0, Currency: 'usd', Email: '', DialCode: '', PhoneNumber: '' };
  paymentMethods: any[] = [];
  selectedPaymentMethodId?: string = "";
  clientSecret: string;

  paymentIntentId: string = "";
  isButtonDisabled: boolean = true;

  constructor(
    private stripeService: StripeService,
    private logService: LogService,
    private fb: FormBuilder,
  ) {
    
  }

  async ngOnInit() {
    this.stripe = await loadStripe(environment.facility[environment.submerchant].stripe.publishedKey, {stripeAccount: environment.facility[environment.submerchant].stripe.accountId}); // Replace with your publishable key
    if(this.pack.IsTaxable)
    {
      this.taxAmount = this.menuPack.Price * (this.taxRegion.TaxRate / 100);
    }
    this.paymentRequest.MenuPackId = this.menuPack.Id;
    this.paymentRequest.Amount = this.menuPack.Price + this.taxAmount;
    this.paymentRequest.Currency = 'usd';
    this.initForm(this.paymentRequest);
    this.isLoading = false;
  }

  initForm(paymentRequest?: PaymentRequest): void {
    const initial: PaymentRequest = paymentRequest || {
      MenuPackId: 0, Amount: 0, Currency: 'usd', Email: '', DialCode: '', PhoneNumber: ''
    };
    this.paymentRequest = initial;
    
    this.paymentForm = this.fb.group({
      Amount: [initial.Amount, [Validators.required]],
      Currency: [initial.Currency, [Validators.required]],
      Email: [initial.Email, [Validators.required, Validators.email]],
      DialCode: [initial.DialCode, [Validators.required]],
      PhoneNumber: [initial.PhoneNumber, [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      MenuPackId: [initial.MenuPackId, [Validators.required]],
    });
  }

  // To trigger validation on focus out
  validateField(fieldName: string): void {
    const control = this.paymentForm.get(fieldName);
    if (control) {
      control.markAsTouched();
      control.updateValueAndValidity();
    }

    // Check if both fields are valid
    if (this.paymentForm.get('Email')?.valid && this.paymentForm.get('DialCode')?.valid && this.paymentForm.get('PhoneNumber')?.valid) {
      this.createPaymentIntent();
    }
  }

  onTermsOfServiceChange(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.agreedTermsOfService = checkbox.checked;
    this.submitButtonToggle();
  }

  submitButtonToggle(): void {
    if(this.paymentForm.get('Email')?.valid && this.paymentForm.get('DialCode')?.valid && this.paymentForm.get('PhoneNumber')?.valid && !this.isLoading && this.paymentIntentCreated && this.agreedTermsOfService){
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  async createPaymentIntent()
  {
    this.isLoading = true;
    this.stripeService.createPaymentIntent(this.paymentForm.value).subscribe((result: PaymentIntentResponse) => {
      if (this.stripe) {
        this.paymentIntentCreated = true;
        this.paymentIntentId = result.Id;
        this.paymentMethods = result.PaymentMethods;
        this.clientSecret = result.ClientSecret;
        this.elements = this.stripe.elements({
          clientSecret: result.ClientSecret,
        });
        this.createPaymentElement();
      }
      this.isLoading = false;
    }); 
  }

  createPaymentElement()
  {
    // Create and mount the Payment Element
    try {
      this.paymentElement = this.elements.create('payment', {
        terms: {
          card: 'always',
          usBankAccount: 'auto'
        },
        fields: {
          billingDetails: {
            name: 'auto',
          }
        }
      });
      this.paymentElement.mount('#payment-element');

      // Listen for changes in the Payment Element
      this.paymentElement.on('change', (event: any) => {
        console.log(event.complete);
        this.paymentDetailsAdded = event.complete;
        this.submitButtonToggle();
        if (event.error) {
          this.logService.error('Error from stripe : ', event.error.message);
        }
      });
      this.isLoading = false;
      
      this.submitButtonToggle();
    } catch (error) {
      console.error('Payment Element initialization failed:', error);
      this.paymentElement = null; // Fallback for unsupported environments
    }
  }

  async handlePayment() {
    if (!this.stripe || !this.paymentElement) return;

    try {
      let result;
      if (this.selectedPaymentMethodId) {
        // Confirm the payment using the saved payment method
        result = await this.stripe.confirmPayment({
          clientSecret: this.clientSecret,
          confirmParams: {
            payment_method: this.selectedPaymentMethodId, // Use the saved payment method
            return_url: `${window.location.origin}/buy/${this.locationId}?success=true&id=${this.paymentIntentId}`,
          }
        });
      } else if (this.paymentElement) {
        // Confirm the payment using the Payment Element for new payment method
        result = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/buy/${this.locationId}?success=true&id=${this.paymentIntentId}`,
          },
          redirect: 'if_required',
        });
      } else {
        console.error('No payment method selected or Payment Element initialized.');
        return;
      }

      if (result.error) {
        console.error('Payment confirmation failed:', result.error.message);
        alert('Payment failed: ' + result.error.message);
        window.location.href = `${window.location.origin}/buy/${this.locationId}?success=false&error=${result.error.message}`;
        return;
      } else if (result.paymentIntent) {
        window.location.href = `${window.location.origin}/buy/${this.locationId}?success=true&id=${this.paymentIntentId}`;
        return;
      }
    } catch (error) {
      this.errorMessage = 'Failed to process bank payment.';
    }
  }

  getTermDuration() {
    return "per " + Utils.getEnumNameFromValue(TermUnit, this.menuPack.TermUnit).toLowerCase();
  }

  selectPaymentMethod(paymentMethodId: string) {
    if(paymentMethodId == "none")
    {
      this.selectedPaymentMethodId = "";
      this.isButtonDisabled = true;
      this.showPaymentElement();
      //this.createPaymentElement();
    } else {
      this.selectedPaymentMethodId = paymentMethodId;
      this.isButtonDisabled = false;
      this.hidePaymentElement();
    }
  }
  
  formatCurrency(amount: number){
    return Utils.formatCurrency(amount);
  }

  toTitleCase(input: string){
    return Utils.toTitleCase(input);
  }

  showPaymentElement() {
    const paymentContainer = document.getElementById('payment-element');
    if (paymentContainer) {
      paymentContainer.style.display = 'block'; // Show the element
    }
  }
  
  hidePaymentElement() {
    const paymentContainer = document.getElementById('payment-element');
    if (paymentContainer) {
      paymentContainer.style.display = 'none'; // Hide the element
    }
  }

  async onSubmit() {
    if (!this.stripe || !this.paymentElement) return;

    if(!this.paymentForm.valid)
    {
      this.isButtonDisabled = true;
      return;
    }
    try {
      await this.handlePayment();
    } catch (err) {
      this.logService.error('Error fetching data', err);
      return throwError(() => err);
    } finally {
      return;
    }
  }
}
